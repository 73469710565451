// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { all, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from '../reducer/auth';
import { actionConnect, signIn, signOut, signUp } from './auth';
import { CaseTypes } from '../reducer/case';
import {
  getAllCases,
  getCaseById,
  handleFinishCase,
  redoCaseById,
} from './case';

const authSagas = [
  takeLatest(AuthTypes.CONNECT, actionConnect),
  takeLatest(AuthTypes.LOGIN, signIn),
  takeLatest(AuthTypes.SIGNUP, signUp),
  takeLatest(AuthTypes.SIGNOUT, signOut),
];

const caseSagas = [
  takeLatest(CaseTypes.HANDLE_FINISH_CASE, handleFinishCase),
  takeLatest(CaseTypes.GET_CASES, getAllCases),
  takeLatest(CaseTypes.GET_CASE_DETAIL, getCaseById),
  takeLatest(CaseTypes.REDO_CASE_DETAIL, redoCaseById),
];

export default function* root() {
  yield all([...authSagas, ...caseSagas]);
}
