import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CaseSelectors } from '../core/adapters/redux/reducer/case';
import { connect } from 'react-redux';
import Loading from '../components/loading';
const UserGuide = lazy(() => import('../screens/userGuide'));
const FinalProcessing = lazy(() => import('../screens/finalProcessing'));
const Cases = lazy(() => import('../screens/cases'));
const Steps = lazy(() => import('../screens/steps'));
const CaseDetail = lazy(() => import('../screens/caseDetail'));
const StepProcessingCapture = lazy(
  () => import('../screens/stepProcessingCapture'),
);
const StepProcessingVideo = lazy(
  () => import('../screens/stepProcessingVideo'),
);
const TestVideoLandscape = lazy(
  () => import('../screens/testModel/TestVideoLandscape'),
);
const TestVideoPortrait = lazy(
  () => import('../screens/testModel/TestVideoPortrait'),
);
const TestImage = lazy(() => import('../screens/testModel/TestImage'));
const TestRecord = lazy(() => import('../screens/testModel/TestRecord'));
const Log = lazy(() => import('../screens/log'));
const TableLog = lazy(() => import('../screens/log/tableLog'));
const ResultRecord = lazy(() => import('../screens/resultRecord'));
const RefImages = lazy(() => import('../screens/refImages'));

type Props = {
  appMode: string;
};

const Home = (props: Props) => {
  const { appMode } = props;
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/cases" element={<Cases />} />
        <Route path="/cases/:id" element={<CaseDetail />} />
        <Route path="/cases/:id/log" element={<Log />} />
        <Route path="/cases/:id/refImages" element={<RefImages />} />
        <Route path="/steps" element={<Steps />} />
        <Route path="/user-guide" element={<UserGuide />} />
        <Route
          path="/step-processing-video"
          element={<StepProcessingVideo />}
        />
        <Route
          path="/step-processing-capture"
          element={<StepProcessingCapture />}
        />
        <Route path="/final-processing" element={<FinalProcessing />} />
        {appMode === 'development' && (
          <>
            <Route
              path="/testvideolandscape"
              element={<TestVideoLandscape />}
            />
            <Route path="/testvideoportrait" element={<TestVideoPortrait />} />
            <Route path="/testimg" element={<TestImage />} />
            <Route path="/testrecord" element={<TestRecord />} />
            <Route path="/log" element={<TableLog />} />
            <Route path="/result" element={<ResultRecord />} />
          </>
        )}
        <Route path="/*" element={<Cases />} />
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  appMode: CaseSelectors.getAppMode(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
