import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CaseSelectors } from './app/core/adapters/redux/reducer/case';
import { ICaptureData } from './app/core/entities';

import { AuthSelectors } from './app/core/adapters/redux/reducer/auth';
import { AuthenticationRoutes, HomeRoutes } from './app/routes';

type AppProps = {
  recordVideoData: ICaptureData;
  vinCaptureData: ICaptureData;
  odoCaptureData: ICaptureData;
  dmgCaptureData: ICaptureData;
  isLoggedIn: boolean;
  appReady: boolean;
};

const App = ({
  recordVideoData,
  vinCaptureData,
  odoCaptureData,
  dmgCaptureData,
  isLoggedIn,
  appReady,
}: AppProps) => {
  const handler = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    if (
      recordVideoData.status !== 'Pending' ||
      vinCaptureData.status !== 'Pending' ||
      odoCaptureData.status !== 'Pending' ||
      dmgCaptureData.status !== 'Pending'
    ) {
      window.addEventListener('beforeunload', handler);

      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
  }, [recordVideoData, vinCaptureData, odoCaptureData, dmgCaptureData]);

  if (!appReady) return <></>;

  return <>{isLoggedIn ? <HomeRoutes /> : <AuthenticationRoutes />}</>;
};

const mapStateToProps = (state) => ({
  recordVideoData: CaseSelectors.getRecordVideoData(state),
  vinCaptureData: CaseSelectors.getVinCaptureData(state),
  odoCaptureData: CaseSelectors.getOdoCaptureData(state),
  dmgCaptureData: CaseSelectors.getDmgCaptureData(state),
  isLoggedIn: AuthSelectors.isLoggedIn(state),
  appReady: AuthSelectors.appReady(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
