// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { call, put } from 'redux-saga/effects';
import { AuthTypes } from '../../reducer/auth';
import ApiService from '../../../../services/api';
import Cookies from 'js-cookie';

const api = ApiService;

export function* actionConnect() {
  const token = Cookies.get('access_token');
  let user = null;
  if (token) {
    api.addAuthorizationHeader(token);
    const response = yield call(getUserDetail);
    if (!response) {
      Cookies.remove('access_token');
      location.reload();
      return;
    }
    const { result } = response;
    user = result;
  }
  yield put({
    type: AuthTypes.CONNECT_SUCCESS,
    payload: { isLoggedIn: !!token, user },
  });
}

export function* signIn({ params, callback }) {
  try {
    const token = yield call(api.login, params);
    const response = yield call(getUserDetail);
    const { result } = response;
    yield put({
      type: AuthTypes.LOGIN_SUCCESS,
      payload: {
        isLoggedIn: !!token,
        user: result,
      },
    });
    callback && callback();
  } catch (ex) {
    console.log(ex);
    yield put({
      type: AuthTypes.LOGIN_FAILURE,
      payload: { error: ex?.response?.data?.detail || 'Sign in error' },
    });
  }
}

export function* signUp({ params, callback }) {
  try {
    delete params.confirm_password;
    yield call(api.register, params);
    yield put({
      type: AuthTypes.SIGNUP_SUCCESS,
    });
  } catch (ex) {
    yield put({
      type: AuthTypes.SIGNUP_FAILURE,
      payload: { error: ex?.response?.data?.detail || 'Sign up error' },
    });
  }
}

export function* getUserDetail() {
  try {
    const response = yield call(api.fetchUser);
    return response;
  } catch (ex) {
    yield put({
      type: AuthTypes.SIGNUP_FAILURE,
      payload: { error: { ...ex, message: ex.message || '' } },
    });
  }
}

export function* signOut() {
  yield call(api.logout);
  yield put({
    type: AuthTypes.SIGNOUT_SUCCESS,
  });
  location.reload();
}
