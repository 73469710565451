import React from 'react';
import { Container } from 'react-bootstrap';
import { Spinner } from 'reactstrap';

const Loading = () => {
  return (
    <Container
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spinner />
    </Container>
  );
};

export default Loading;
