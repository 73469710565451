import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  region: process.env.REACT_APP_S3_REGION,
});

class UploadService {
  INSTANCE = undefined;

  constructor() {
    if (!this.INSTANCE) {
      this.INSTANCE = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_S3_BUCKET },
      });
    }
  }

  upload = async (params) => {
    const {
      image: base64img,
      caseID,
      category,
      bin,
      time,
      refTime,
      overRef,
    } = params;

    let uploadKey = category;
    if (category === 'OVERVIEW_BIN') {
      if (overRef) {
        uploadKey = `OVERVIEW_REF_BIN_${bin}_time_${time}`;
      } else {
        uploadKey = `${category}_${bin}_time_${time}`;
      }
    } else if (category === 'ZOOMIN_TIME') {
      uploadKey = `${category}_reftime_${refTime}_${time}`;
    } else if (category === 'REF_TIME') {
      uploadKey = `${category}_${refTime}`;
    } else if (category === 'TRANSITION') {
      uploadKey = `transition_time_${refTime
        .toString()
        .split('_')
        .join('')}_${time}`;
    }

    return await fetch(base64img)
      .then((res) => res.blob())
      .then(async (image) => {
        const params = {
          Bucket: `${process.env.REACT_APP_S3_BUCKET}/${caseID}/input`,
          Key: `${uploadKey.toLowerCase()}.jpg`,
          Body: image,
          ContentEncoding: 'base64',
          ContentType: 'image/jpeg',
          ACL: 'public-read',
        };
        const response = await this.INSTANCE.upload(params).promise();
        const { Location } = response;
        if (category === 'TRANSITION') {
          return {
            category,
            bin,
            time: time,
            refTime: refTime,
            url: Location,
            s3_bucket: process.env.REACT_APP_S3_BUCKET,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        }
        if (category === 'ZOOMIN_TIME' || category === 'REF_TIME') {
          return {
            category,
            bin,
            zoomin_time: time,
            url: Location,
            s3_bucket: process.env.REACT_APP_S3_BUCKET,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        } else {
          return {
            category,
            bin,
            time,
            url: Location,
            s3_bucket: process.env.REACT_APP_S3_BUCKET,
            s3_path: `${caseID}/input/${uploadKey.toLowerCase()}.jpg`,
          };
        }
      });
  };

  uploadLog = (data) => {
    const { log, caseID } = data;
    const s3 = new AWS.S3();

    const s3ObjectName = `${caseID}.txt`;

    const textLog = JSON.stringify(log);

    const blob = new Blob([textLog], { type: 'text/plain' });

    // Tải tệp văn bản lên S3
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${caseID}/log`,
      Key: s3ObjectName,
      Body: blob,
      ContentType: 'text/plain',
      ACL: 'public-read',
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Lỗi tải lên S3:', err);
      } else {
        console.log('Tải lên S3 thành công. Đường dẫn tới tệp:', data.Location);
      }
    });
  };

  uploadVideo = (data) => {
    const { video, caseID } = data;
    const s3 = new AWS.S3();

    const refImageName = `refImages.json`;

    const jsonVideo = JSON.stringify(video);

    const blob = new Blob([jsonVideo], { type: 'text/plain' });

    // Tải tệp văn bản lên S3
    const params = {
      Bucket: `${process.env.REACT_APP_S3_BUCKET}/${caseID}/input`,
      Key: refImageName,
      Body: blob,
      ContentType: 'text/plain',
      ACL: 'public-read',
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Lỗi tải lên S3:', err);
      } else {
        console.log('Tải lên S3 thành công. Đường dẫn tới tệp:', data.Location);
      }
    });
  };
}

export default new UploadService();
