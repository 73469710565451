// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  connect: [],
  connectSuccess: [],

  login: ['params', 'callback'],
  loginSuccess: ['token'],
  loginFailure: ['error'],

  signup: ['params', 'callback'],
  signupSuccess: ['token'],
  signupFailure: ['error'],

  signout: [],
  signoutSuccess: [],

  checkAuthentication: [],
  checkAuthenticationSuccess: [],

  setError: ['payload'],
});

export const INITIAL_STATE = Immutable({
  error: null,
  user: undefined,
  isProcessing: false,
  appReady: false,
  isLoggedIn: false,
  timeSignUp: '',
});

const connect = (state = INITIAL_STATE) => state;

const setError = (state = INITIAL_STATE, { payload }) => {
  return state.set('error', payload);
};

const connectSuccess = (state = INITIAL_STATE, { payload }) =>
  state
    .set('isLoggedIn', payload.isLoggedIn)
    .set('user', payload.user)
    .set('appReady', true);

const requestAuth = (state = INITIAL_STATE) => {
  return state.set('isProcessing', true).set('error', null);
};

const requestAuthSuccess = (state = INITIAL_STATE, { payload }) => {
  const nextState = state
    .set('isLoggedIn', payload.isLoggedIn)
    .set('user', payload.user)
    .set('error', null)
    .set('isProcessing', false);
  return nextState;
};

const requestAuthFailure = (state = INITIAL_STATE, { payload }) => {
  return state.set('error', payload.error).set('isProcessing', false);
};

const requestSuccess = (state = INITIAL_STATE) => {
  return state
    .set('error', null)
    .set('isProcessing', false)
    .set('timeSignUp', new Date().getTime());
};

const signOutSuccess = (state = INITIAL_STATE) => {
  return state.set('timeSignUp', '');
};

export const AuthTypes = Types;

export const authReducer = createReducer(INITIAL_STATE, {
  [Types.CONNECT]: connect,
  [Types.CONNECT_SUCCESS]: connectSuccess,
  [Types.LOGIN]: requestAuth,
  [Types.LOGIN_SUCCESS]: requestAuthSuccess,
  [Types.LOGIN_FAILURE]: requestAuthFailure,
  [Types.SIGNUP]: requestAuth,
  [Types.SIGNUP_SUCCESS]: requestSuccess,
  [Types.SIGNUP_FAILURE]: requestAuthFailure,
  [Types.SIGNOUT]: signOutSuccess,
  [Types.SIGNOUT_SUCCESS]: signOutSuccess,
  [Types.SET_ERROR]: setError,
});

export const AuthActions = Creators;

const AuthSelector = (state) => state.auth;

export const AuthSelectors = {
  error: createSelector(AuthSelector, (auth) => auth.error),
  isLoggedIn: createSelector(AuthSelector, (auth) => auth.isLoggedIn),
  isProcessing: createSelector(AuthSelector, (auth) => auth.isProcessing),
  user: createSelector(AuthSelector, (auth) => auth.user),
  appReady: createSelector(AuthSelector, (auth) => auth.appReady),
};
