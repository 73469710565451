/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './app/styles/App.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './app/core/framework/redux';
import './app/core/framework/config';
import App from './App';
import { AuthActions } from './app/core/adapters/redux/reducer/auth';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './app/helpers/scrollToTop';

const container = document.getElementById('root');
const root = createRoot(container!);

store.dispatch(AuthActions.connect());

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
